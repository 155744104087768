<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-card class="mx-auto my-auto">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <avatar-edit
                                            v-model="user.avatar_url"
                                            @error="(m) => this.avatarErr = m"
                                            @file-added="avatarErr = null"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-if="avatarErr">
                                    <v-col>
                                        <v-alert type="error">
                                            {{ avatarErr }}
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    v-model="user.first_name"
                                                    label="First name"
                                                    placeholder=""
                                                />
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    v-model="user.last_name"
                                                    label="Last name"
                                                    placeholder=""
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-btn @click="handleUpdateProfile">Update</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
    </dashboard-layout>
</template>

<script>
import User from "@/models/User";
import AvatarEdit from "@/components/AvatarEdit";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "Profile",
    components: {DashboardLayout, AvatarEdit},
    data: function () {
        return {
            user: {},
            avatarErr: null,
        }
    },
    methods: {
        async handleUpdateProfile() {
            let user = new User(this.user);
            this.user = await user.save()
        }
    },
    mounted() {
        this.user = this.$auth.user()
    }
}
</script>

<style scoped>

</style>
